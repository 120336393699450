<template>
  <div>
    <div class="pa-0 px-1 pb-3 w-full d-flex align-center justify-end">
      <v-btn
        fab
        small
        color="primary"
        :ripple="false"
        @click="isCreating = true"
      >
        <v-icon>
          {{ icons.mdiPlus }}
        </v-icon>
      </v-btn>
    </div>

    <v-tabs
      v-model="currentTabIdx"
      background-color="transparent"
      class="mb-5 elevation-0"
    >
      <v-tab
        v-for="tabItem in tabItems"
        :key="`tab-for-${tabItem.compName}`"
      >
        {{ tabItem.text }}
      </v-tab>
    </v-tabs>

    <v-tabs-items
      v-model="currentTabIdx"
      style="background: transparent"
      touchless
    >
      <v-tab-item
        v-for="(tabItem, tabItemIdx) in tabItems"
        :key="`tab-item-for-${tabItem.compName}`"
      >
        <component
          :is="tabItem.compName"
          v-if="currentTabIdx === tabItemIdx"
          :is-creating="isCreating"
          @close="isCreating = false"
          @initialized="isLoading = false"
        />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { mdiPlus } from '@mdi/js'
import BenefitTags from '@/views/components/book_tags/BenefitTags.vue'
import ExpenseTags from '@/views/components/book_tags/ExpenseTags.vue'

export default {
  components: {
    BenefitTags,
    ExpenseTags,
  },
  setup() {
    return {
      currentTabIdx: ref(0),
      isCreating: ref(false),
      tabItems: [
        { compName: 'BenefitTags', text: '諸入' },
        { compName: 'ExpenseTags', text: '諸費' },
      ],

      icons: {
        mdiPlus,
      },
    }
  },
}
</script>
