<template>
  <v-skeleton-loader
    v-if="isLoading"
    type="table"
  />

  <div v-else>
    <v-dialog
      :value="isCreating"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title>
          諸入ラベル
        </v-card-title>

        <v-card-text>
          <v-radio-group
            v-model="addTarget.type"
            row
          >
            <v-radio
              v-for="(selectTag, selectTagIdx) in selectTags"
              :key="`selectTags[${selectTagIdx}]`"
              :label="selectTag.text"
              :value="selectTag.value"
            ></v-radio>
          </v-radio-group>

          <v-text-field
            v-model="addTarget.name"
            :error="!addTarget.name"
            label="名前"
            required
          />
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :ripple="false"
            color="primary"
            @click="create"
          >
            作成
          </v-btn>
          <v-btn
            :ripple="false"
            color="blue darken-1"
            text
            @click="$emit('close')"
          >
            キャンセル
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-data-table
      ref="dataTable"
      :headers="headers"
      :items="benefitTags"
      group-by="displayType"
      item-key="kid"
      :header-props="{ sortByText: 'ソート' }"
      hide-default-footer
      disable-pagination
      no-data-text="データがありません"
    >
      <template #[`item.attributes.name`]="{item}">
        <yona-edit-dialog
          :display-name="item.name"
          @open="edit(item)"
          @save="update"
        >
          <template #input>
            <v-text-field
              v-if="isEditing"
              v-model="editTarget.name"
              placeholder="名前"
            />
          </template>
        </yona-edit-dialog>
      </template>

      <template #[`group.header`]="{group}">
        <td :colspan="headers.length">
          {{ group }}
        </td>
      </template>

      <template #[`item.actions`]="{item}">
        <yona-edit-dialog
          title="削除の確認"
          btn-color="error"
          save-text="同意の上で削除"
          @save="destroy(item)"
        >
          <template #append-outer-display-name>
            <v-btn
              icon
              :ripple="false"
            >
              <v-icon small>
                {{ icons.mdiTrashCanOutline }}
              </v-icon>
            </v-btn>
          </template>

          <template #input>
            <span>
              このラベルに関連しているすべての諸入データも削除します。削除を実行してもよろしいですか
            </span>
          </template>
        </yona-edit-dialog>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import {
  ref,
  reactive,
  getCurrentInstance,
} from '@vue/composition-api'
import {
  mdiTrashCanOutline,
  mdiPlus,
} from '@mdi/js'
import BenefitTagApi from '@/api/waiter/BenefitTag'
import YonaEditDialog from '@/views/components/util/YonaEditDialog.vue'

export default {
  components: {
    YonaEditDialog,
  },
  props: {
    isCreating: {
      type: Boolean,
      default: false,
    },
  },
  setup(_, { emit }) {
    const vm = getCurrentInstance().proxy

    const selectTags = [
      { text: '大ラベル', value: 'benefit_primary_label' },
      { text: '小ラベル', value: 'benefit_secondary_label' },
    ]

    const isLoading = ref(false)
    const isEditing = ref(false)
    const benefitTags = ref([])

    const editTarget = reactive({ id: null, name: null, type: '' })
    const addTarget = reactive({ name: '', type: selectTags[0].value })

    const setType = type => tag => {
      return {
        ...tag,
        displayType: type === 'benefit_primary_label' ? '大ラベル' : '小ラベル',
        kid: `${type}_${tag.id}`,
        type,
      }
    }

    const updateRefTags = res => {
      const { primaryTags, secondaryTags } = res.data.benefitTags.data.attributes.tags

      benefitTags.value = [
        ...primaryTags.map(setType('benefit_primary_label')),
        ...secondaryTags.map(setType('benefit_secondary_label')),
      ]
    }

    const edit = item => {
      isEditing.value = true

      editTarget.id = item.id
      editTarget.name = item.name
      editTarget.type = item.type
    }

    const create = async () => {
      if (!addTarget.name || !addTarget.type) return
      const res = await BenefitTagApi.createBenefitTag(addTarget)

      if (res?.data) {
        updateRefTags(res)
        vm.$toast.success('諸入ラベルを作成しました')
      }

      addTarget.name = ''
      addTarget.type = selectTags[0].value
      emit('close')
    }

    const update = async index => {
      const { id, name, type } = editTarget
      const res = await BenefitTagApi.updateBenefitTag({ id, name, type })

      if (res?.data) {
        updateRefTags(res)
        vm.$toast.success('諸入ラベルを更新しました')
      }

      isEditing.value = false
      editTarget.id = null
      editTarget.name = ''
      editTarget.type = ''
    }

    const destroy = async ({ id, type }) => {
      const res = await BenefitTagApi.deleteBenefitTag({ id, type })

      if (res?.data) {
        updateRefTags(res)
        vm.$toast.success('諸入ラベルを削除しました')
      }
    }

    const getBenefitTags = async () => {
      isLoading.value = true

      const res = await BenefitTagApi.getBenefitTags()

      if (res?.data) {
        updateRefTags(res)
      }

      isLoading.value = false
    }

    getBenefitTags()

    return {
      // data
      isLoading,
      isEditing,
      benefitTags,
      headers: [
        { text: '名前', value: 'attributes.name' },
        {
          text: '',
          value: 'actions',
          align: 'end',
          sortable: false,
        },
      ],
      selectTags,
      editTarget,
      addTarget,

      // methods
      edit,
      update,
      destroy,
      create,

      icons: {
        mdiTrashCanOutline,
        mdiPlus,
      },
    }
  },
}
</script>
